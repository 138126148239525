import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import "date-fns";
import { observer } from "mobx-react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import { getNewDate } from "../../DateCreator";
import GetAppIcon from "@material-ui/icons/GetApp";
import { jsonToCsvDataFormatter } from "../JsonToCsvDataFormatter";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { advisorySearch } from "../AdvisorySearch";
import { ViewAllAdvisoriesTable } from "./ViewAllAdvisoriesTable";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import useStopwatch from "../../hooks/useStopWatch";
import { Header } from "../Header";
import Button from "@material-ui/core/Button";
import LoadingDots from "../../Loading";


export const ViewAllAdvisories = observer(({ ...props }) => {
    const [allAdvisories, setAllAdvisories] = useState([]);
    const [allFilteredAdvisories, setAllFilteredAdvisories] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [csvLoading, setCSVLoading] = useState(false);
    const [page, setPage] = useState(0);
    const pageRef = useRef(0)
    const [pageLoaded, setPageLoaded] = useState(false);
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortBy, setSortBy] = useState("advisoryRecords.advisoryFormEntity.submittedTime");
    const [sortLoaded, setSortLoaded] = useState(false);
    const triggerRef = useRef(false)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [isHealthy, setHealthy] = useState(true);
    const history = useHistory();
    const { timeInMillisRef, pause } = useStopwatch();
    const [pageData, setPageData] = useState({})
    const { trackEvent } = useMatomo();
    //CSV link doesn't work with async calls, so use hack...
    const csvLinkRef = useRef()
    const [searchQuery, setSearchQuery] = useState("");

    const setFilteredAdvisories = ()=> {
        //const advisoriesToUse = allAdvisories.length > 0 ? allAdvisories : firstPageOfAdvisories
        //setPage(0)
        if (searchQuery === "") {
            setAllFilteredAdvisories(allAdvisories);
        } else {
            setAllFilteredAdvisories(advisorySearch(searchQuery, allAdvisories));
        }
    };

    useEffect(() => {
        setFilteredAdvisories()
    }, [allAdvisories, searchQuery])

    useEffect(() => {
        if (!csvLoading) {
            console.log("nothing to load.")
            return;
        }

        console.log("getting csv data...")
        props.viewAllAdvisoriesAPIClient.getAllAdvisories().then(response => {
            pause()
            const timeInSeconds = timeInMillisRef.current / 1000
            trackEvent({ category: "download", action: "all advisories csv", value: 1 })
            setCsvData(jsonToCsvDataFormatter(response))
        }).catch(error => console.warn(error));
        console.log("Retrieved csv data!")
    }, [csvLoading])

    useEffect(() => {
        if (csvData.length > 0 && csvLoading) {
            csvLinkRef.current.link.click()
            setCSVLoading(false)
        }
    }, [csvData])


    useEffect(() => {
        props.viewAllAdvisoriesAPIClient
            .getPageOfAdvisories(page, rowsPerPage, sortBy, sortOrder)
            .then(response => {
                pause()
                const timeInSeconds = timeInMillisRef.current / 1000
                trackEvent({ category: "load time", action: "advisory page loaded", value: timeInSeconds })
                setPageData(response)
                setAllAdvisories(response.content);
                setPageLoaded(true)
                setDataLoaded(true);
                setSortLoaded(true)
                pageRef.current = page
                console.log(`Retrieved ${rowsPerPage} for page ${page} of advisories.`)//response.content)
            })
            .catch(error => console.warn(error));
        props.getHealthApiClient
            .getHealth()
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                setHealthy(false)
                console.warn(error)
            });


    }, [page, rowsPerPage, triggerRef.current]);

    return (
        <Grid container spacing={3} className="pageContainer">
            {isHealthy ? <></> :
                <Grid item xs={12} className="banner">
                    <p>Ford Advisory Management is currently experiencing intermittent issues contacting the Ford email
                        server (mrl.azell.com).</p>
                    <p>The email server may be down or experiencing network issues.</p>
                    <p>You may experience failures sending advisories during this time.</p>
                </Grid>
            }
            {isDataLoaded ? (
                <>
                    <Header cdsid={props.cdsid} title={"Advisory List"} />
                    <Grid container item md>
                        <Grid item md className={"createAdvisoryGrid"}
                            style={{
                                display: "flex",
                                height: "100%"
                            }}>
                            <Button
                                className="createAdvisoryButton"
                                color="primary"
                                variant="contained"
                                disableRipple
                                onClick={() => history.push("/createAdvisory")}
                                style={{ display: "inline-block" }}
                            >

                                <AddCircleOutlineIcon
                                    className="createAdvisoryIcon"
                                    fontSize="large"
                                    style={{ verticalAlign: "middle" }}
                                />
                                <span style={{ alignItems: "center" }}>
                                    Create an Advisory
                                </span>
                            </Button>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                id="search"
                                variant="outlined"
                                placeholder="Press Enter to search this page by id, date, app, status, or modified by"
                                helperText= {searchQuery === ""?"":"Clear the search to see all data in this page."}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon color="disabled" />
                                        </InputAdornment>
                                    )
                                }}
                                onBlur={(event) => {
                                    setSearchQuery(event.target.value)
                                }}
                                onKeyUp={event => {
                                    if (event.key === "Enter") {
                                        setSearchQuery(event.target["value"])
                                    }

                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ViewAllAdvisoriesTable
                            page={page}
                            setPage={setPage}
                            pageRef={pageRef}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                            sortOrder={sortOrder}
                            setSortOrder={setSortOrder}
                            rowsPerPage={rowsPerPage}
                            pageLoaded={pageLoaded}
                            setRowsPerPage={setRowsPerPage}
                            setPageLoaded={setPageLoaded}
                            sortLoaded={sortLoaded}
                            setSortLoaded={setSortLoaded}
                            triggerRef={triggerRef}
                            setAllFilteredAdvisories={setAllFilteredAdvisories}
                            allFilteredAdvisories={allFilteredAdvisories}
                            pageData={pageData}
                        />
                    </Grid>
                    <Grid item md>
                        {csvLoading == true ? (
                            <span><LoadingDots loadingText="Loading" /></span>
                        ) : (
                            <IconButton
                                className="exportAdvisoryCSVButton"
                                color="primary"
                                disableRipple
                                onClick={() => {
                                    if (!csvLoading) {
                                        setCSVLoading(true)
                                    }
                                }}
                            >
                                <GetAppIcon className="exportAdvisoryCSVIcon" fontSize="small" />
                                Export Advisories (Last 30 Days)
                            </IconButton>
                        )}
                        <CSVLink
                            className="csvLink"
                            data={csvData}
                            filename={"Advisories_Data_" + getNewDate() + ".csv"}
                            ref={csvLinkRef}
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <Header cdsid={props.cdsid} />
                    <div className="loadingAdvisories">
                        <div className="loadingAdvisoriesText">Fetching advisories ...</div>
                        <img
                            src={require("../../Graphics/FAM-Loading-Screen-Preloader.gif")}
                            alt="Loading"
                        />
                    </div>
                </>
            )}
        </Grid>
    );
});
