import React, { useState } from 'react';
import './loading.css';

const LoadingDots = ({loadingText}) => {
  return (
    <span className="loading-dots">
      <span style={{fontSize:14}}>{loadingText}</span>
      <div className="dot"/><div className="dot"/><div className="dot"/>
    </span>
  );
};

export default LoadingDots;