import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Hidden from "@material-ui/core/Hidden";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import React, {useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {ViewAllAdvisoriesTableColumn} from "./ViewAllAdvisoriesTableColumn";
import LoadingDots from "../../Loading";

export const ViewAllAdvisoriesTable = props => {

    const history = useHistory();

    const handleChangePage = (event, newPage) => {
        props.setPageLoaded(false);
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setPageLoaded(false);
        props.setRowsPerPage(parseInt(event.target.value,10));
        if(props.pageRef.current!==0){
            props.setPage(0);
        }
    };

    const StyledTableCell = withStyles(() => ({
        head: {
            backgroundColor: "#F2F2F2"
        }
    }))(TableCell);
    const StyledTableRow = withStyles(() => ({
        hover: {
            cursor: "pointer"
        }
    }))(TableRow);

    return (
        <Paper>
            <Table>
                <TableHead>
                    <TableRow data-testid="tableHead">
                        <Hidden xsDown>
                            <StyledTableCell>
                                <ViewAllAdvisoriesTableColumn
                                    page={props.page}
                                    setPage={props.setPage}
                                    pageRef={props.pageRef}
                                    sortBy={props.sortBy}
                                    setSortBy={props.setSortBy}
                                    sortOrder={props.sortOrder}
                                    setSortOrder={props.setSortOrder}
                                    setSortLoaded={props.setSortLoaded}
                                    triggerRef={props.triggerRef}
                                    columnName={props.sortBy==="id" && props.sortLoaded==false?<LoadingDots loadingText=""/>:"ID"}
                                    apiColumnMapping={"id"}
                                    allFilteredAdvisories={props.allFilteredAdvisories}
                                    setAllFilteredAdvisories={props.setAllFilteredAdvisories}
                                />
                            </StyledTableCell>
                        </Hidden>
                        <StyledTableCell>
                            <ViewAllAdvisoriesTableColumn
                                page={props.page}
                                setPage={props.setPage}
                                pageRef={props.pageRef}
                                sortBy={props.sortBy}
                                setSortBy={props.setSortBy}
                                sortOrder={props.sortOrder}
                                setSortOrder={props.setSortOrder}
                                setSortLoaded={props.setSortLoaded}
                                triggerRef={props.triggerRef}
                                columnName={props.sortBy==="advisoryRecords.advisoryFormEntity.situationBackground.submittedTime" && props.sortLoaded==false?<LoadingDots loadingText=""/>:"Date Issued"}
                                apiColumnMapping={"advisoryRecords.advisoryFormEntity.situationBackground.submittedTime"}
                                allFilteredAdvisories={props.allFilteredAdvisories}
                                setAllFilteredAdvisories={props.setAllFilteredAdvisories}
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <ViewAllAdvisoriesTableColumn
                                page={props.page}
                                setPage={props.setPage}
                                pageRef={props.pageRef}
                                sortBy={props.sortBy}
                                setSortBy={props.setSortBy}
                                sortOrder={props.sortOrder}
                                setSortOrder={props.setSortOrder}
                                setSortLoaded={props.setSortLoaded}
                                triggerRef={props.triggerRef}
                                columnName={props.sortBy==="advisoryRecords.advisoryFormEntity.product" && props.sortLoaded==false?<LoadingDots loadingText=""/>:"Application"}
                                apiColumnMapping={"advisoryRecords.advisoryFormEntity.product"}
                                allFilteredAdvisories={props.allFilteredAdvisories}
                                setAllFilteredAdvisories={props.setAllFilteredAdvisories}
                            />
                        </StyledTableCell>
                        <Hidden xsDown>
                            <StyledTableCell>
                                <ViewAllAdvisoriesTableColumn
                                    page={props.page}
                                    setPage={props.setPage}
                                    pageRef={props.pageRef}
                                    sortBy={props.sortBy}
                                    setSortBy={props.setSortBy}
                                    sortOrder={props.sortOrder}
                                    setSortOrder={props.setSortOrder}
                                    setSortLoaded={props.setSortLoaded}
                                    triggerRef={props.triggerRef}
                                    columnName={props.sortBy==="advisoryRecords.advisoryFormEntity.status" && props.sortLoaded==false?<LoadingDots loadingText=""/>:"Status"}
                                    apiColumnMapping={"advisoryRecords.advisoryFormEntity.status"}
                                    allFilteredAdvisories={props.allFilteredAdvisories}
                                    setAllFilteredAdvisories={props.setAllFilteredAdvisories}
                                />
                            </StyledTableCell>
                        </Hidden>
                        <Hidden xsDown>
                            <StyledTableCell>
                                <ViewAllAdvisoriesTableColumn
                                    page={props.page}
                                    setPage={props.setPage}
                                    pageRef={props.pageRef}
                                    sortBy={props.sortBy}
                                    setSortBy={props.setSortBy}
                                    sortOrder={props.sortOrder}
                                    setSortOrder={props.setSortOrder}
                                    setSortLoaded={props.setSortLoaded}
                                    triggerRef={props.triggerRef}
                                    columnName={props.sortBy==="advisoryRecords.advisoryFormEntity.submittedTime" && props.sortLoaded==false?<LoadingDots loadingText=""/>:"Last Modified"}
                                    apiColumnMapping={"advisoryRecords.advisoryFormEntity.submittedTime"}
                                    allFilteredAdvisories={props.allFilteredAdvisories}
                                    setAllFilteredAdvisories={props.setAllFilteredAdvisories}
                                />
                            </StyledTableCell>
                        </Hidden>
                        <Hidden xsDown>
                            <StyledTableCell>
                                <ViewAllAdvisoriesTableColumn
                                    page={props.page}
                                    setPage={props.setPage}
                                    pageRef={props.pageRef}
                                    sortBy={props.sortBy}
                                    setSortBy={props.setSortBy}
                                    sortOrder={props.sortOrder}
                                    setSortOrder={props.setSortOrder}
                                    setSortLoaded={props.setSortLoaded}
                                    triggerRef={props.triggerRef}
                                    columnName={props.sortBy==="advisoryRecords.advisoryFormEntity.cdsid" && props.sortLoaded==false?<LoadingDots loadingText=""/>:"Modified By"}
                                    apiColumnMapping={"advisoryRecords.advisoryFormEntity.cdsid"}
                                    allFilteredAdvisories={props.allFilteredAdvisories}
                                    setAllFilteredAdvisories={props.setAllFilteredAdvisories}
                                />
                            </StyledTableCell>
                        </Hidden>
                    </TableRow>
                </TableHead>
                <TableBody style={{ opacity: (props.sortLoaded==false && props.pageLoaded==true) || (props.pageLoaded==false && props.sortLoaded==true) ? 0 : 1, transition: 'opacity 0.3s ease-in-out' }}>
                    {props.allFilteredAdvisories
                        .map((advisory, index) => (
                            <StyledTableRow
                                onClick={() => {
                                    history.push("/updateAdvisory/" + advisory.id);
                                }}
                                hover
                                data-testid={"tableRow" + index}
                                key={"advisory-table" + index}
                            >
                                <Hidden xsDown>
                                    <TableCell>{advisory.id}</TableCell>
                                </Hidden>
                                <TableCell>
                                    {advisory.advisoryForm.situationBackground.submittedTime.toLocaleString(
                                        "en-US",
                                        {
                                            month: "numeric",
                                            day: "numeric",
                                            year: "numeric"
                                        }
                                    )}
                                </TableCell>
                                <TableCell>{advisory.advisoryForm.product}</TableCell>
                                <Hidden xsDown>
                                    <TableCell>{advisory.advisoryForm.status}</TableCell>
                                </Hidden>
                                <Hidden xsDown>
                                    <TableCell>
                                        {advisory.advisoryForm.submittedTime.toLocaleString(
                                            "en-US",
                                            {
                                                month: "numeric",
                                                day: "numeric",
                                                year: "numeric",
                                                hour: "numeric",
                                                minute: "numeric"
                                            }
                                        )}
                                    </TableCell>
                                </Hidden>
                                <Hidden xsDown>
                                    <TableCell>{advisory.advisoryForm.cdsid}</TableCell>
                                </Hidden>
                            </StyledTableRow>
                        ))}
                </TableBody>
            </Table>
            <TablePagination
                className="tablePagination"
                rowsPerPageOptions={[25,50,100]}
                component="div"
                count={parseInt(props.pageData.totalElements)}
                rowsPerPage={parseInt(props.rowsPerPage)}
                page={parseInt(props.pageData.number)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="View:"
            />
        </Paper>
    );
};
