import React from "react";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { ArrowDropDown } from "@material-ui/icons";

export const ViewAllAdvisoriesTableColumn = ({
    page,
    setPage,
    pageRef,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    setSortLoaded,
    columnName,
    apiColumnMapping,
    allFilteredAdvisories,
    setAllFilteredAdvisories,
    triggerRef
}) => {

    function handleSortingByColumn() {
        console.log("Changing Sort Order...")
        setSortLoaded(false)
        setSortOrder(order => order = sortBy === apiColumnMapping && sortOrder === "desc" ? "asc" : "desc");
        setSortBy(sort => sort = apiColumnMapping);
        if(pageRef.current===0 || pageRef.current === page){
            setPage(p=>p=0);
            triggerRef.current = !triggerRef.current;
        } else {
            setPage(p=>p=0);
        }
    }

    return (
        <TableSortLabel
            active={sortBy === apiColumnMapping}
            direction={sortBy === apiColumnMapping ? sortOrder : "desc"}
            onClick={() => {
                handleSortingByColumn();
            }}
            IconComponent={ArrowDropDown}
        >
            {columnName}
        </TableSortLabel>
    );
};
